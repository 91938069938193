<template>
  <ModelEditor
    v-if="site"
    :title="title"
    :model="site"
    :errors="errors"
    :loading="$apollo.loading"
    :canSave="isDirty"
    :canCancel="isDirty"
    @save="save"
    @cancel="restore"
  >
    <!-- TODO: Fix search of assets -->
    <SiteStyled v-if="site">
      <AutoForm :model.sync="site" :modelName="'Site'" :filterFields="filterFields" />
      <ModelAssociator
        :title="'Assets'"
        :models="models"
        :sourceModelName="'site'"
        :targetModelName="'asset'"
        :targetModelNamePlural="'machines'"
        :whereSearchInputType="'MachineFilterInput'"
        :canBulkAddAssets="true"
        @connect="addAsset"
        @disconnect="deleteAsset"
      >
        <template slot="bulkAddAssets">
          <AddSubsidiaryAssetsToSite @refetch="refetch" :mode="'addAssetsToSite'" :site="site"></AddSubsidiaryAssetsToSite>
        </template>
      </ModelAssociator>
    </SiteStyled>
  </ModelEditor>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import ModelEditor from '@/components/misc/ModelEditor'
import { AutoForm, ModelAssociator, AddSubsidiaryAssetsToSite } from '@common/components'
import { FlashMessages } from '@common/singletons'

import SITE_QUERY from '#/graphql/sites/site.gql'
import UPDATE_SITE_MUTATION from '#/graphql/sites/updateSite.gql'

const SiteStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
`

export default {
  components: {
    SiteStyled,
    ModelEditor,
    AutoForm,
    ModelAssociator,
    AddSubsidiaryAssetsToSite,
  },
  data() {
    return {
      site: null,
      siteLocal: null,
      errors: [],
      disabledFields: ['createdAt', 'updatedAt'],
      filterFields: ['assets'],
    }
  },
  computed: {
    title() {
      return `Site ${this.site?.name ?? ''}`
    },
    models() {
      return get(this.site, 'assets', []).map(asset => ({
        ...asset,
        label: asset.name,
      }))
    },
    isDirty() {
      return !isEqual(this.site, this.siteLocal)
    },
  },
  methods: {
    refetch() {
      this.$apollo.queries.site.refetch()
    },
    async save() {
      const data = { ...this.site }
      delete data.id
      delete data.__typename
      this.filterFields.forEach(key => {
        delete data[key]
      })
      this.disabledFields.forEach(key => {
        delete data[key]
      })

      try {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_SITE_MUTATION,
          variables: {
            where: {
              id: this.$route.params.id,
            },
            data,
          },
        })

        this.siteLocal = res?.data.updateSite ?? null
        FlashMessages.$emit('success', `Site ${this.siteLocal.name} saved successfully`, {
          timeout: 3000,
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async restore() {
      this.site = this.siteLocal
    },
    async addOrDeleteAsset(id, type, mode, entity) {
      const subsidiaryID = this.$route.params.id
      if (!subsidiaryID || !entity || !mode) {
        return
      }
      //create mode mandates a assetType in schema. to delete the assets added though script, the assettype match needs to be skipped.
      //The below if-else will be removed in the meta-store version.
      let assignedAssets
      if (mode === 'delete' || mode === 'deleteMany') {
        assignedAssets = {
          [mode]: [
            {
              assetId: id,
            },
          ],
        }
      } else {
        assignedAssets = {
          [mode]: [
            {
              assetId: id,
              assetType: type,
            },
          ],
        }
      }
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SITE_MUTATION,
        variables: {
          where: {
            id: subsidiaryID,
          },
          data: {
            assignedAssets,
          },
        },
      })
      if (res) {
        this.refetch()
      }
    },
    async addAsset({ id, __typename }) {
      await this.addOrDeleteAsset(id, __typename, 'create', 'assets')
    },
    async deleteAsset({ id, __typename }) {
      return this.addOrDeleteAsset(id, __typename, 'deleteMany', 'assets')
    },
  },
  apollo: {
    site: {
      query: SITE_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      manual: true,
      result({ data }) {
        this.site = data?.site
        this.siteLocal = data?.site
      },
    },
  },
}
</script>
