var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.site ? _c('ModelEditor', {
    attrs: {
      "title": _vm.title,
      "model": _vm.site,
      "errors": _vm.errors,
      "loading": _vm.$apollo.loading,
      "canSave": _vm.isDirty,
      "canCancel": _vm.isDirty
    },
    on: {
      "save": _vm.save,
      "cancel": _vm.restore
    }
  }, [_vm.site ? _c('SiteStyled', [_c('AutoForm', {
    attrs: {
      "model": _vm.site,
      "modelName": 'Site',
      "filterFields": _vm.filterFields
    },
    on: {
      "update:model": function updateModel($event) {
        _vm.site = $event;
      }
    }
  }), _c('ModelAssociator', {
    attrs: {
      "title": 'Assets',
      "models": _vm.models,
      "sourceModelName": 'site',
      "targetModelName": 'asset',
      "targetModelNamePlural": 'machines',
      "whereSearchInputType": 'MachineFilterInput',
      "canBulkAddAssets": true
    },
    on: {
      "connect": _vm.addAsset,
      "disconnect": _vm.deleteAsset
    }
  }, [_c('template', {
    slot: "bulkAddAssets"
  }, [_c('AddSubsidiaryAssetsToSite', {
    attrs: {
      "mode": 'addAssetsToSite',
      "site": _vm.site
    },
    on: {
      "refetch": _vm.refetch
    }
  })], 1)], 2)], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }